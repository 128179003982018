<template>
  <div>
    <h2>Отход производства</h2>
    <wasteproduction-table></wasteproduction-table>
  </div>
</template>

<script>
import WasteproductionTable from "@/components/ecologist/wasteproduction/wasteproductionTable";
export default {
  name: "wasteproductionMain",
  components: { WasteproductionTable },
};
</script>

<style scoped></style>
