<template>
  <div>
    <wasteproduction-main></wasteproduction-main>
  </div>
</template>

<script>
import WasteproductionMain from "@/components/ecologist/wasteproduction/wasteproductionMain";
export default {
  name: "wasteproductionMainView",
  components: { WasteproductionMain },
};
</script>

<style scoped></style>
